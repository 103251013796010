import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useContext, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { AppContext } from '../../App';
import { Map as MapGl } from 'mapbox-gl';
import Map, { NavigationControl, useMap } from 'react-map-gl';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';
import { useFleetLocations } from '../../dataHooks/fleetAndFleetgroupHooks';
import { useUser } from '../../dataHooks/adminHooks';
import { FleetItemType, isCustomerItem, isDeviceItem, isFleetgroupItem, isFleetItem, TreeItemType } from '../../model/frontendDataModels';
import { LoginContext } from '../../Login';
import { backendFleetLocationToItem } from '../../model/convertModels';
import { BackendFleetLocation } from '../../model/backendDataModels';
import MapPinActive from '../../icons/map_pin_active.svg';
import MapPinInactive from '../../icons/map_pin_inactive.svg';
import MapPinWarning from '../../icons/map_pin_warning.svg';
import { handleMapLoad, SelectableMarker, updateMap, updateMapData } from './mapView/mapUtils';

type Size = {
  width?: number;
  height?: number;
};

type MapHandlerProps = {
  selectableMarkers: SelectableMarker[];
  width: number;
  height: number;
  expandItems: (itemIds: string[]) => void;
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
};

function MapHandler(props: MapHandlerProps): JSX.Element {
  const map = useMap();
  const currentMapRef = map.current;

  function handleSelectionCallback(selectableMarker: SelectableMarker): void {
    props.setItemSelection(JSON.parse(selectableMarker.fleetItem as unknown as string) as FleetItemType, true);
    props.expandItems(JSON.parse(selectableMarker.expandItems as unknown as string) as string[]);
  }

  function handleMapLoadCallback(): void {
    if (currentMapRef) {
      handleMapLoad(currentMapRef, props.selectableMarkers, props.width, handleSelectionCallback);
    }
  }

  useEffect(() => {
    if (currentMapRef) {
      currentMapRef.once('load', handleMapLoadCallback);
    }
  }, [currentMapRef]);

  useEffect(() => {
    if (map.current) {
      const currentMap = map.current;

      const imageActive = new Image(26, 43);
      imageActive.onload = (): void => {
        if (!currentMap.hasImage('map-pin-active')) {
          currentMap.addImage('map-pin-active', imageActive);
        }
      };

      imageActive.src = MapPinActive;

      const imageInactive = new Image(26, 43);
      imageInactive.onload = (): void => {
        if (!currentMap.hasImage('map-pin-inactive')) {
          currentMap.addImage('map-pin-inactive', imageInactive);
        }
      };

      imageInactive.src = MapPinInactive;

      const imageWarning = new Image(26, 43);
      imageWarning.onload = (): void => {
        if (!currentMap.hasImage('map-pin-warning')) {
          currentMap.addImage('map-pin-warning', imageWarning);
        }
      };

      imageWarning.src = MapPinWarning;
    }
  }, [map.current]);

  useEffect(() => {
    if (map.current) {
      const currentMap = map.current;

      currentMap.resize();
      updateMap(currentMap, props.selectableMarkers, props.width);
    }
  }, [props.width, props.height]);

  useEffect(() => {
    if (currentMapRef && props.selectableMarkers.length > 0) {
      const currentMap: MapGl = currentMapRef.getMap();

      if (currentMapRef.loaded()) {
        updateMapData(currentMap, props.selectableMarkers);
        updateMap(currentMapRef, props.selectableMarkers, props.width);
      } else {
        currentMapRef.once('idle', () => {
          updateMapData(currentMap, props.selectableMarkers);
          updateMap(currentMapRef, props.selectableMarkers, props.width);
        });
      }
    }
  }, [props.selectableMarkers]);

  return <></>;
}

type MapViewProps = {
  selectedItem?: TreeItemType;
  expandItems: (itemIds: string[]) => void;
  setItemSelection: (item: TreeItemType, selected: boolean) => void;
};

export default function MapView(props: MapViewProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const { data: user, error: userError } = useUser(loginContext.accessToken);

  if (userError) {
    appContext.addBackendError(userError);
  }

  const { data: fleetLocations, error: fleetLocationsError } = useFleetLocations(user?.customerId, loginContext.accessToken);

  if (fleetLocationsError) {
    appContext.addBackendError(fleetLocationsError);
  }

  const ref = useRef<HTMLDivElement>(null);
  const [{ width, height }, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  const onResize = useDebounceCallback(setSize, 0);

  useResizeObserver({
    ref,
    onResize,
  });

  const [selectableMarkers, setSelectableMarkers] = useState<SelectableMarker[]>([]);

  useEffect(() => {
    if (fleetLocations) {
      let markers: BackendFleetLocation[] = [];

      if (props.selectedItem) {
        if (isDeviceItem(props.selectedItem)) {
          const deviceItem = props.selectedItem;

          markers = fleetLocations.filter(location => location.fleetId === deviceItem.fleetId);
        } else if (isCustomerItem(props.selectedItem)) {
          const customerItem = props.selectedItem;

          markers = fleetLocations.filter(
            location => location.parents.find(parent => parent.customerId === customerItem.customerId) !== undefined
          );
        } else if (isFleetgroupItem(props.selectedItem)) {
          const fleetgroupItem = props.selectedItem;

          markers = fleetLocations.filter(
            location => location.parents.find(parent => parent.fleetgroupId === fleetgroupItem.fleetgroupId) !== undefined
          );
        } else if (isFleetItem(props.selectedItem)) {
          const fleetItem = props.selectedItem;

          markers = fleetLocations.filter(location => location.fleetId === fleetItem.fleetId);
        }
      } else {
        markers = fleetLocations;
      }

      const filteredMarkers = markers.filter(fleet => fleet.lat !== 0 && fleet.lng !== 0);

      if (filteredMarkers.length > 0) {
        setSelectableMarkers(
          filteredMarkers.map(fleet => {
            return {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              lat: fleet.lat!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              lng: fleet.lng!,
              fleetItem: backendFleetLocationToItem(fleet),
              expandItems: [fleet.fleetId, ...fleet.parents.map(parent => parent?.customerId ?? parent?.fleetgroupId ?? '')],
              state: fleet.alarms > 0 || fleet.gateway === 'offline' ? 'warning' : fleet.gateway === 'none' ? 'inactive' : 'active',
            };
          })
        );
      }
    }
  }, [fleetLocations, props.selectedItem]);

  const mapBoxUsername = process.env.REACT_APP_MAPBOX_USERNAME || '';
  const mapBoxStyleId = process.env.REACT_APP_MAPBOX_STYLE_ID || '';
  const mapBoxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

  const mapBoxUrl = `https://api.mapbox.com/styles/v1/${mapBoxUsername}/${mapBoxStyleId}?access_token=${mapBoxAccessToken}`;

  return (
    <Box ref={ref} sx={{ flexGrow: 1 }}>
      <Map reuseMaps style={{ height: '100vh' }} mapStyle={mapBoxUrl}>
        <MapHandler
          selectableMarkers={selectableMarkers}
          width={width ?? 0}
          height={height ?? 0}
          expandItems={props.expandItems}
          setItemSelection={props.setItemSelection}
        />
        <NavigationControl />
      </Map>
    </Box>
  );
}
