import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import LoadingIndicator from '../../../common/LoadingIndicator';
import LabelledInput from './common/LabelledInput';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import { subHours, addHours } from 'date-fns';
import AxisOptionsSelect from './common/AxisOptionsSelect';
import InputRow from './common/InputRow';
import { SWRResponse } from 'swr';
import { LogChart, TemperatureModeType } from '../../../../model/backendDataModels';
import Store from '../../../../store/Store';
import MultiAxisChart, { AxisType, AxisTypes } from './common/multiAxisChart/MultiAxisChart';
import InputSelect from './InputSelect';
import { Typography } from '@mui/material';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import { InstantChartSettings } from '../../../../reducers/Reducer';
import moment from 'moment';
import { utcToZonedTime } from 'date-fns-tz';
import { convertTemperature } from '../../../../utils/temperatureUtils';
import ValidatedDatePicker from './common/ValidatedDatePicker';

function roundToNearestHour(date: Date): Date {
  date.setMinutes(date.getMinutes() + 30);
  date.setMinutes(0, 0, 0);

  return date;
}

type InstantChartTabProps = {
  dataHook: (startDate: Date, endDate: Date) => SWRResponse<LogChart, BackendError>;
  productCategory: 'bmu' | 'charger';
};

function getConvertedData(data: number[][], types: string[], temperatureMode: TemperatureModeType): number[][] {
  const convertedData: number[][] = [];

  for (let index = 0; index < data.length; ++index) {
    if (types[index] === 'temperature') {
      convertedData.push(
        data[index].map(celcius => {
          return convertTemperature(celcius, temperatureMode, 1);
        })
      );
    } else {
      convertedData.push(data[index]);
    }
  }

  return convertedData;
}

export default function InstantChartTab({ dataHook, productCategory }: InstantChartTabProps): JSX.Element {
  const { state, dispatch } = useContext(Store);

  const appContext = useContext(AppContext);

  const now = utcToZonedTime(new Date(), state.timezone);
  const [startDate, setStartDate] = useState<Date>(
    state.logFilesSettings.instantChartSettings
      ? state.logFilesSettings.instantChartSettings.startDate
      : roundToNearestHour(subHours(now, 24))
  );
  const [endDate, setEndDate] = useState<Date>(
    state.logFilesSettings.instantChartSettings ? state.logFilesSettings.instantChartSettings.endDate : now
  );
  const [leftAxisOptions, setLeftAxisOptions] = useState<string[]>(
    initiateChartSettings(productCategory === 'bmu' ? ['Average battery current', 'Average battery voltage'] : ['Charger current'])
  );
  const [rightAxisOptions, setRightAxisOptions] = useState<string[]>(
    initiateChartSettings(productCategory === 'bmu' ? ['Average state of charge'] : ['Charger voltage'])
  );

  const [axisType, setAxisType] = useState<AxisType>('Category');
  const { data, error } = dataHook(startDate, endDate);

  if (error) {
    appContext.addBackendError(error);
  }

  function initiateChartSettings(initialSelected: string[]): string[] {
    if (
      state.logFilesSettings.instantChartSettings &&
      initialSelected.every(option => state.logFilesSettings.instantChartSettings?.rightAxisOptions.includes(option))
    ) {
      return state.logFilesSettings.instantChartSettings.rightAxisOptions;
    } else {
      return initialSelected;
    }
  }

  useEffect(() => {
    dispatch({ type: 'SET_INSTANT_CHART_SETTINGS', payload: { startDate, endDate, leftAxisOptions, rightAxisOptions } });
  }, [startDate, endDate, leftAxisOptions, rightAxisOptions]);

  useEffect(() => {
    const payload: InstantChartSettings = {
      startDate,
      endDate,
      leftAxisOptions: [],
      rightAxisOptions: [],
    };

    if (data && !leftAxisOptions.every(option => data.y_names.includes(option))) {
      if (productCategory === 'charger' && data.y_names.includes('Charger current')) {
        setLeftAxisOptions(['Charger current']);
        payload.leftAxisOptions = ['Charger current'];
      }

      if (
        productCategory === 'bmu' &&
        ['Average battery current', 'Average battery voltage'].every(option => data.y_names.includes(option))
      ) {
        setLeftAxisOptions(['Average battery current', 'Average battery voltage']);
        payload.leftAxisOptions = ['Average battery current', 'Average battery voltage'];
      }
    }

    if (data && !rightAxisOptions.every(option => data.y_names.includes(option))) {
      if (productCategory === 'charger' && data.y_names.includes('Charger voltage')) {
        setRightAxisOptions(['Charger voltage']);
        payload.leftAxisOptions = ['Charger voltage'];
      }

      if (productCategory === 'bmu' && data.y_names.includes('Average State Of Charge')) {
        setRightAxisOptions(['Average State Of Charge']);
        payload.leftAxisOptions = ['Average State Of Charge'];
      }
    }

    dispatch({ type: 'SET_INSTANT_CHART_SETTINGS', payload: payload });
  }, [data]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', borderTop: '2px solid black', padding: `${COMPONENT_PADDING}px`, height: '100%' }}>
      <InputRow>
        <LabelledInput
          label='Start date'
          input={
            <ValidatedDatePicker
              value={startDate}
              updateValue={(value: Date): void => {
                if (endDate.getTime() - value.getTime() > 24 * 7 * 60 * 60 * 1000) {
                  setEndDate(addHours(value, 24 * 7));
                } else if (value.getTime() > endDate.getTime()) {
                  const timeDiff = endDate.getTime() - startDate.getTime();
                  const newEndDate = addHours(value, timeDiff / (60 * 60 * 1000));

                  if (newEndDate.getTime() > now.getTime()) {
                    setEndDate(now);
                  } else {
                    setEndDate(newEndDate);
                  }
                }

                setStartDate(value);
              }}
              maxDate={roundToNearestHour(subHours(now, 24))}
            />
          }
        />
        <LabelledInput
          label='End date'
          input={
            <ValidatedDatePicker
              value={endDate}
              updateValue={(value: Date): void => {
                setEndDate(value);
              }}
              minDate={roundToNearestHour(addHours(startDate, 24))}
              maxDate={roundToNearestHour(addHours(startDate, 24 * 7))}
            />
          }
        />
        {data && !error && (
          <>
            {data.x.length > 0 && (
              <AxisOptionsSelect
                label='Left Axis'
                unique='left-axis'
                axisOptions={data.y_names.filter(opt => !rightAxisOptions.includes(opt))}
                selectedOptions={leftAxisOptions}
                updateSelectedOptions={setLeftAxisOptions}
              />
            )}
            {data.x.length > 0 && (
              <AxisOptionsSelect
                label='Right Axis'
                unique='right-axis'
                axisOptions={data.y_names.filter(opt => !leftAxisOptions.includes(opt))}
                selectedOptions={rightAxisOptions}
                updateSelectedOptions={setRightAxisOptions}
              />
            )}
            {data.x.length > 0 && (
              <InputSelect
                label='Axis type'
                value={axisType}
                items={AxisTypes}
                updateValue={(data: string): void => setAxisType(data as AxisType)}
              />
            )}
          </>
        )}
      </InputRow>
      {data && !error && (
        <>
          {(leftAxisOptions.length > 0 || rightAxisOptions.length > 0) && data.x.length > 0 && (
            <MultiAxisChart
              title='Instant Chart'
              chartType='Line'
              axisType={axisType}
              xValues={data.x.map(xValue => moment.parseZone(xValue, moment.ISO_8601))}
              data={getConvertedData(data.y, data.type, state.temperatureMode)}
              allOptions={data.y_names}
              leftAxisOptions={leftAxisOptions}
              rightAxisOptions={rightAxisOptions}
            />
          )}
          {data.x.length === 0 && <Typography variant='sleekHeader'>No Data</Typography>}
        </>
      )}
      {!data && !error && <LoadingIndicator />}
    </Box>
  );
}
