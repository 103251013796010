import React, { ReactNode, MouseEvent, useEffect, useRef } from 'react';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import Icon from '../Icon';
import Box from '@mui/material/Box';

type RowLabelProps = {
  label: string;
  selected: boolean;
  onClick?: (event: MouseEvent) => void;
};

function RowLabel({ label, selected, onClick }: RowLabelProps): JSX.Element {
  const theme = useTheme();

  return (
    <Box
      onClick={(event: MouseEvent): void => {
        if (onClick) {
          event.stopPropagation();
          onClick(event);
        }
      }}
    >
      <Typography
        variant='tableText'
        align='left'
        sx={{
          color: selected ? theme.palette.action.selected : theme.palette.text.primary,
          paddingLeft: '10px',
          cursor: onClick ? 'pointer' : 'default',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

type ArrowButtonProps = {
  onClick: (() => void) | null;
  selected: boolean;
  down?: boolean;
};

function ArrowButton({ onClick, selected, down }: ArrowButtonProps): JSX.Element {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        marginRight: '5px',
        color: selected ? theme.palette.action.selected : theme.palette.text.primary,
        transform: down ? 'rotate(90deg)' : '',
        cursor: 'pointer',
      }}
      onClick={(event: MouseEvent): void => {
        if (onClick) {
          event.stopPropagation();
          onClick();
        }
      }}
    >
      <Icon name='arrow' sx={{ fontSize: '13px' }} />
    </Box>
  );
}

type ExpandableRowProps = {
  label: string;
  iconName: string;
  depth: number;
  selected: boolean;
  select?: (event: MouseEvent) => void;
  expanded: boolean;
  expandTree: () => void;
  collapseTree: () => void;
  onlineStatus?: boolean;
  hasWarning: boolean;
  children: ReactNode;
  loading: boolean;
};

export default function ExpandableRow({
  label,
  iconName,
  depth,
  selected,
  select,
  expanded,
  expandTree,
  collapseTree,
  onlineStatus,
  hasWarning,
  children,
  loading,
}: ExpandableRowProps): JSX.Element {
  const theme = useTheme();

  const componentRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (componentRef.current && selected) {
        componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  }, [selected, componentRef]);

  const unselectedSx = {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: `${15 + 20 * depth}px`,
    marginBottom: '3px',
    paddingRight: '20px',
  };

  const selectedSx = {
    ...unselectedSx,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.action.selected,
    fontWeight: 'bold',
  };

  const childArray = (React.Children.toArray(children).filter(child => React.isValidElement(child)) as React.ReactElement[]).filter(
    child => !Array.isArray(child)
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box ref={componentRef} sx={selected ? selectedSx : unselectedSx}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            variant='columnHeader'
            sx={{
              marginLeft: childArray.length > 0 || loading ? '0px' : '19px',
              cursor: select ? 'pointer' : 'default',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  marginRight: '5px',
                }}
              >
                <CircularProgress size={13} />
              </Box>
            ) : (
              childArray.length > 0 && <ArrowButton onClick={expanded ? collapseTree : expandTree} selected={selected} down={expanded} />
            )}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            color={hasWarning || onlineStatus === false ? theme.palette.error.main : '#7297AC'}
          >
            <Icon name={iconName} sx={{ fontSize: '16px' }} />
          </Typography>

          <RowLabel label={label} selected={selected} onClick={select} />
        </Box>
      </Box>
      {expanded && children}
    </Box>
  );
}
