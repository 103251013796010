import { mutate, SWRResponse } from 'swr';
import {
  BackendDevice,
  BasicLogInfo,
  BluOverviewStatus,
  BmuOverviewStatus,
  ChargerOverviewStatus,
  ConfigData,
  FieldsAndValues,
  LogChart,
  LogChartOptions,
  LogData,
} from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useDeviceConfig(device: BackendDevice, accessToken: string | null): SWRResponse<ConfigData, BackendError> {
  const fullUrl = getFullUrl(`/${device.productCategory}/${device.mui}/config`);

  return getUseSwr<ConfigData>(fullUrl, accessToken);
}

export function useDeviceHistoryLogs(
  type: 'bms' | 'bmu' | 'charger',
  mui: string,
  from: Date,
  to: Date,
  accessToken: string | null
): SWRResponse<LogData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/history`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });

  return getUseSwr<LogData>(fullUrl, accessToken);
}

export function useHistoryChart(
  type: 'bmu' | 'charger',
  mui: string,
  from: Date,
  to: Date,
  accessToken: string | null
): SWRResponse<LogChart, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/historychart`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });

  return getUseSwr<LogChart>(fullUrl, accessToken);
}

export function useHistoryChartOptions(type: 'bmu' | 'charger', accessToken: string | null): SWRResponse<LogChartOptions, BackendError> {
  const fullUrl = getFullUrl(`/${type}/historychartoptions`);

  return getUseSwr<LogChartOptions>(fullUrl, accessToken);
}

export function useDeviceEventLogs(
  type: 'bms' | 'bmu' | 'charger',
  mui: string,
  from: Date,
  to: Date,
  accessToken: string | null
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/event`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });

  return getUseSwr<FieldsAndValues>(fullUrl, accessToken);
}

export function useBasicLogInfo(mui: string, accessToken: string | null): SWRResponse<BasicLogInfo, BackendError> {
  const fullUrl = getFullUrl(`/device/${mui}/logs/basicinfo`);

  return getUseSwr<BasicLogInfo>(fullUrl, accessToken);
}

export function useInstantChart(
  type: 'bmu' | 'charger',
  mui: string,
  from: Date,
  to: Date,
  accessToken: string | null
): SWRResponse<LogChart, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/instant`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });

  return getUseSwr<LogChart>(fullUrl, accessToken);
}

export function useDevices(customerId: string, fleetId: string, accessToken: string | null): SWRResponse<BackendDevice[], BackendError> {
  const fullUrl = getFullUrl(`/customer/${customerId}/fleet/${fleetId}/devices`);

  return getUseSwr<BackendDevice[]>(fullUrl, accessToken);
}

export function mutateDevices(customerId: string, fleetId: string): Promise<void> {
  if (fleetId === '') {
    fleetId = 'unassigned';
  }

  const fullUrl = getFullUrl(`/customer/${customerId}/fleet/${fleetId}/devices`);

  return mutate<void>(fullUrl);
}

export function useDevice(mui: string | undefined, accessToken: string | null): SWRResponse<BackendDevice, BackendError> {
  const fullUrl = mui ? getFullUrl(`/device/${mui}`) : null;

  return getUseSwr<BackendDevice>(fullUrl, accessToken);
}

export type GatewayOverviewStatus = {
  initialized: boolean;
};

export function useStatus(
  selectedDevice: BackendDevice | undefined,
  accessToken: string | null
): SWRResponse<BmuOverviewStatus | BluOverviewStatus | ChargerOverviewStatus | GatewayOverviewStatus, BackendError> {
  const fullUrl = selectedDevice ? getFullUrl(`/device/${selectedDevice?.mui}/overview/status`) : null;

  return getUseSwr<BmuOverviewStatus | BluOverviewStatus | ChargerOverviewStatus | GatewayOverviewStatus>(fullUrl, accessToken);
}
